export const userQlFlow = (state, updateUserQlData, showReviewStoryDrawer) => {
  let entireUrl = JSON.parse(localStorage.getItem("urlParams"))?.entire_url;
  let brandArray = [
    "mahindra",
    "piaggio",
    "osm",
    "euler",
    "altigreen",
    "etrio",
    "greaves",
  ];

  const brandPresent = brandArray.filter((item) =>
    entireUrl?.toLowerCase().includes(item)
  );

  const payloadQL = {
    ...state?.userQlData,
    brandName: brandPresent?.length > 0 ? brandPresent[0] : "",
    stage: brandPresent?.length > 0 ? "selected-brand" : "",
  };

  updateUserQlData(payloadQL);
  showReviewStoryDrawer();
};
