import { sendAnalytics } from "../utils/analyticsCommon";
import LeadService from "./leadService";
import sessionTracking from "./sessionTracking";

export const validLeadConvert = (leadPayload, analyticsPayload) => {
  console.log(
    "event_name",
    leadPayload?.phone,
    localStorage?.getItem("phoneInfo")
  );
  if (leadPayload?.phone !== localStorage?.getItem("phoneInfo")) {
    LeadService(leadPayload)
      .then((response) => {
        const sessionInfo = {
          leadUuid: response?.data?.uuid,
          newSession: true,
          formSubmitted: true,
          leadIntent: "MEDIUM_INTENT",
        };

        sessionTracking(sessionInfo);
        sendAnalytics("reserve_vehicle_button_clicked", analyticsPayload, "en");
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
};
