import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, InputAdornment, styled, Typography } from "@mui/material";
import axios from "axios";
import { useRouter } from "next/router";
import React, { useContext, useState } from "react";

import { primary } from "../../constants/theme";
import { Context } from "../../context/context";
import { MemoizedButton } from "../../SDK/button";
import { MemoizedOTPField } from "../../SDK/input/otpInput";
import { MemoizedPhoneField } from "../../SDK/input/phoneInput";
import { checkError } from "../../SDK/utils/validationHelpers";
import LeadService from "../../services/leadService";
import sessionTracking from "../../services/sessionTracking";
import { sendAnalytics } from "../../utils/analyticsCommon";
import { createCookieInHour, getCookie } from "../../utils/cookie";

const CustomOtpComponent = styled(Box)(({ theme }) => ({
  justifyContent: "flex-start",
  width: "100%",

  ".input-box .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl":
    {
      paddingRight: "0px",
    },

  ".otp-buttons": {
    display: "block",
    border: "transparent",
    background: primary.secondary,
    boxShadaw: "0",
    padding: "13px",
    borderRadius: "0 8px 8px 0",
    color: primary.white,
  },
  ".otp-buttons: disabled": {
    background: primary.secondary,
    border: 0,
    opacity: "0.5",
  },
  ".otp-buttons: hover": {
    background: primary.secondary,
    border: "transparent",
  },

  [theme.breakpoints.down("md")]: {
    ".otp-buttons": {
      display: "block",
      border: "transparent",
      background: primary.secondary,
      boxShadaw: "0",
      padding: "13px",
      borderRadius: "0 8px 8px 0",
      color: primary.white,
    },
  },
}));

const OTP = ({ checked, form, type, connect_type, input_type, style }) => {
  const [error, setError] = useState("");
  const [buttonText, setButtonText] = useState("Get  OTP");
  const state = useContext(Context);
  const location = useRouter();
  const pathname = location.pathname;

  let leadIntent =
    location.pathname === "/vehicles"
      ? "MEDIUM_INTENT"
      : location.pathname === "/" ||
          location.pathname === "faqs" ||
          location.pathname === "contactus"
        ? "LOW_INTENT"
        : "HIGH_INTENT";

  const {
    showSnackbar,
    changeVerifiedStatus,
    showOtpComponent,
    hideOtpComponent,
    updateVerificationStatus,
  } = useContext(Context);

  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  const [verified, setVerified] = useState(
    cacheInfo?.verified !== undefined ? cacheInfo?.verified : false
  );
  const [showOtpField, setShowOtpField] = useState(false);

  const getOtpHandler = () => {
    const payload = {
      name: form?.values?.name ?? "",
      phone: form?.values?.mobile,
      pan: "",
      pincode: "",
      dob: "",
      state: "",
      optedExchange: "",
      vehicleSelected: "",
      language: "",
      refNumber: "",
      subSource: "TestDriveForm",
    };
    let analytics_payload = {
      user_name: form?.values?.name ?? "",
      phone_number: form?.values?.mobile,
      state: state?.selectedState,
      type: type,
      connect_type: connect_type,
      input_type: input_type,
    };
    sendAnalytics("send_otp_clicked", analytics_payload, "en");

    LeadService(payload)
      .then((response) => {
        const sessionInfo = {
          leadUuid: response?.data?.uuid,
          newSession: true,
          formSubmitted: true,
          leadIntent: leadIntent,
        };

        sessionTracking(sessionInfo);
        // showSnackbar("We will get back to you soon!!", "success");
      })
      .catch(() => {
        showSnackbar(
          "Oops, something went wrong. Please try again later.",
          "error"
        );
      });
    const url = `/v1/user/generate-otp`;
    let getOtpPayload = {
      mobileNumber: form?.values?.mobile,
      firstName: "",
      middleName: "",
      otp: "",
    };
    axios
      .post(url, getOtpPayload)
      .then((response) => {
        if (response.data.status === "success") {
          showOtpComponent();
          setButtonText("OTP Sent");
          setShowOtpField(true);
          showSnackbar("OTP sent successfully to your number", "success");
        } else {
          showSnackbar(response.data.message, "error");
        }
      })
      .catch((err) => {
        showSnackbar("Oops, Something went wrong Try Again!!", "error");
      });
  };

  const verifyOtpHandler = (otp) => {
    const url = `/v1/user/ext/mobile-sign-in`;
    const payload = {
      mobileNumber: form?.values?.mobile,
      firstName: "",
      middleName: "",
      otp: otp,
    };
    let analytics_payload = {
      user_name: form?.values?.name ?? "",
      phone_number: form?.values?.mobile,
      state: state?.selectedState,
      type: type,
      connect_type: connect_type,
      input_type: input_type,
    };
    sendAnalytics("verify_otp_clicked", analytics_payload, "en");
    axios
      .post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          const cookiePayload = {
            token: response.data.payload.generatedToken,
          };
          createCookieInHour("user_token", cookiePayload, 10);
          setVerified(true);
          hideOtpComponent();
          setShowOtpField(false);
          let analytics_payload = {
            user_name: form?.values?.name ?? "",
            phone_number: form?.values?.mobile,
            state: state?.selectedState,
            type: type,
            connect_type: connect_type,
            input_type: input_type,
            is_otp_verified: true,
          };
          sendAnalytics("otp_verify", analytics_payload, "en");
          changeVerifiedStatus();
          const payload_leads = {
            name: form?.values?.name ?? "",
            phone: form?.values?.mobile,
            pan: "",
            pincode: "",
            dob: "",
            state: "",
            optedExchange: "",
            vehicleSelected: "",
            language: "",
            refNumber: "",
            subSource: "TestDriveForm",
            verified: true,
          };
          if (pathname === "/verification") {
            updateVerificationStatus();
          } else {
            LeadService(payload_leads)
              .then((response) => {
                const sessionInfo = {
                  leadUuid: response?.data?.uuid,
                  newSession: true,
                  formSubmitted: true,
                  leadIntent: leadIntent,
                };

                sessionTracking(sessionInfo);
                showSnackbar("We will get back to you soon!!", "success");
              })
              .catch(() => {
                showSnackbar(
                  "Oops, something went wrong. Please try again later.",
                  "error"
                );
              });
          }

          showSnackbar("Your mobile number is verified!!", "success");
        } else {
          let analytics_payload = {
            user_name: form?.values?.name ?? "",
            phone_number: form?.values?.mobile,
            state: state?.selectedState,
            type: type,
            connect_type: connect_type,
            input_type: input_type,
            is_otp_verified: false,
          };
          sendAnalytics("otp_verify", analytics_payload, "en");
          setError(response.data.message);
        }
      })
      .catch(() => {
        setError("Enter correct otp!");
      });
  };

  return (
    <CustomOtpComponent>
      <Box className="otp-styles">
        <form className="otp-form">
          <Box component="form">
            <MemoizedPhoneField
              style={style}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
                endAdornment: pathname === "/verification" && (
                  <InputAdornment position="end">
                    {verified === false ? (
                      <MemoizedButton
                        disabled={!checked}
                        className="otp-buttons"
                        content={buttonText}
                        sx={{
                          boxShadow: 0,
                          bgcolor:
                            buttonText === "OTP Sent"
                              ? `rgba(93, 91, 192, 0.6) !important`
                              : "",
                          color: `${primary.white} !important`,
                        }}
                        handleClick={(e) => {
                          if (form?.values?.mobile?.length === 10) {
                            getOtpHandler();
                          } else {
                            form.errors.mobile =
                              "Enter a valid 10 digit Mobile Number";
                            form.handleChange(e);
                          }
                        }}
                      ></MemoizedButton>
                    ) : (
                      <Box
                        sx={{
                          color: "green",
                          display: "flex",
                        }}
                      >
                        <Typography sx={{ marginRight: "10px" }}>
                          Verified
                        </Typography>
                        <CheckCircleIcon />
                      </Box>
                    )}
                  </InputAdornment>
                ),
              }}
              error={!!checkError("mobile", form)}
              helperText={form?.errors?.mobile}
              className="input-box"
              name="mobile"
              placeholder="Your Mobile"
              value={form?.values?.mobile}
              disabled={cacheInfo?.verified === true ? true : false}
              onChange={(e) => {
                if (
                  !isNaN(Number(e.target.value)) &&
                  e.target.value?.length <= 10 &&
                  form.handleChange
                ) {
                  form.handleChange(e);
                }
              }}
            />
            {state.showOtpModule === true && showOtpField === true && (
              <Typography
                sx={{
                  color: primary.darkGreen,
                  fontSize: "12px",
                  textAlign: "left",
                  paddingLeft: "1em",
                }}
              >
                {`We have sent the otp to ${form?.values?.mobile}`}{" "}
                <span
                  style={{
                    color: "red",
                    cursor: "pointer",
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "5.6em",
                  }}
                  onClick={() => getOtpHandler()}
                >
                  Resend OTP
                </span>
              </Typography>
            )}
          </Box>
          {(state.showOtpModule === true && showOtpField === true) ||
          (pathname === "/verification" && showOtpField === true) ? (
            <Box className="otp-field">
              <MemoizedOTPField
                className="input-box"
                sx={{
                  width: "9em !important",
                  display: "flex",
                  marginBottom: "0.5em",
                  "& .Mui-error": {
                    color: "red",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "red",
                  },
                }}
                error={error?.length > 0 || (!!checkError("otp", form) && true)}
                helperText={error?.length > 0 ? error : form.errors.otp}
                name="otp"
                placeholder="Enter OTP"
                value={form?.values?.otp}
                onChange={(e) => {
                  if (
                    !isNaN(Number(e.target.value)) &&
                    e.target.value?.length <= 6 &&
                    form.handleChange
                  ) {
                    form.handleChange(e);
                    if (e?.target?.value?.length === 6) {
                      verifyOtpHandler(e?.target?.value);
                    }
                  }
                }}
              />
            </Box>
          ) : (
            ""
          )}
        </form>
      </Box>
    </CustomOtpComponent>
  );
};

// export default OTP;

export const MemoizedOTP = React.memo(OTP);
